import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import profileImg from "assets/upload/default_profile.png";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';

function validateEmail(input) {
  var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (input.match(validRegex)) {
    return true;
  }
  return false;
}

function checkPhoneExist(phone) {
  let validation = JSON.parse($.ajax({
      type: "POST",
      url: base_api_url + "/api/v1/authentication/isPhoneTaken",
      async: false,
      cache:false,
      data: {
          "phone": phone
      },
      success: function(result) {
          return result;
      },
  }).responseText);
  return validation.response;
}

const token = localStorage.getItem('token');

function checkEmailExist(elem) {
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain",
        "Authorization": `Bearer ${token}`,
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>");
        swal("Failed!", "Email already taken!", "error");
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function validatePhoneNumber(input_str) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
  return re.test(input_str);
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#c_photourl").val(response.data[0].path);
    submitData();
  });
}

function sendMail(name, email, password) {
  let data = JSON.stringify({
    "name": name,
    "email": email,
    "password":password,
    "link":window.location.origin + "/auth/login/coordinator",
    "contact":"spil@spil.ie"
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: file_api_url + '/api/v1/coordinator/email/registration',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));

  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let pipeSchool = $("#school_id").val().join("%7C");
  let pipeParam = "";

  let requestData = {
    address: $("#c_address").val(),
    company: $("#c_company").val(),
    emailaddress: $("#c_email").val(),
    language: $("#c_language").val(),
    mobile: $("#c_mobile").val(),
    isactive:true,
    name: $("#c_name").val(),
    photosharingagreedandread:1,
    photourl: $("#c_photourl").val(),
    telephone: $("#c_telephone").val(),
    timestamp: timestamp
  }

  if (pipeSchool.length>0){
    pipeParam = "?pipeSepratedSchool=" + pipeSchool;
  }

  return axios.post(base_api_url + "/api/v1/coord/register" + pipeParam, requestData).then(res => {
    sendMail($("#c_name").val(), $("#c_email").val(), res.data.response.credentials);
    swal("Success","Successfully create data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/" + user_login.role + "/coordinator";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function getDataSchool() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

class CoordinatorRegistration extends React.Component {

  componentDidMount(){
    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      if ($("#photo").val()==="") {
        $("#photo").parent().parent().append("<span class='errorCs'>File required!</span>");
      } else {

        $.each($('form input'), function(index, formField) {
          if ($(formField).val()==="") {
            cv = $(formField).parent().find("label").text();
            if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden"  && !$(formField).attr("readonly")) {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        });

        let checkValidateEmail = validateEmail($("#c_email").val());

        if (!checkValidateEmail) {
          $("#c_email").parent().append("<span class='errorCs'>Email format is invalid.</span>");
          notValid+=1;
        }

        if (isNaN($("#c_telephone").val())) {
          $("#c_telephone").parent().append("<span class='errorCs'>Telephone must be numeric.</span>");
          notValid+=1;
        }

        if (isNaN($("#c_mobile").val())) {
          $("#c_mobile").parent().append("<span class='errorCs'>Mobile phone must be numeric.</span>");
          notValid+=1;
        }

        let valmob = checkPhoneExist($("#c_mobile").val());
        if (valmob==true) {
          $("#c_mobile").parent().append("<span class='errorCs'>Mobile phone is exists.</span>");
          notValid+=1;
        }

        console.log(valmob)
        if (notValid>0) {
          //$("#msgValidation").show();
        } else {
          checkEmailExist($("#c_email"))
        }

      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    let select2Init = $(".select2-tc").select2({placeholder:"School",closeOnSelect : false,
      allowHtml: true,
      allowClear: true,
      tags: false
    });

    $(document).on('change','select.select2-tc', function (e) {
      e.preventDefault(); 
      let counter = select2Init.select2('data').length;
      $(".select2Counter1").remove();
      if (counter>0) {
        $(this).parent().find(".select2-selection--multiple").append("<span class='select2Counter1'>"+counter+" selected</span>")
      }

      if ($(this).is(':selected')){
        //
      } else {
        //
      }
    });

    getDataSchool();

    $(document).on("keyup","#c_telephone",function(){
      let val = isNaN($(this).val());
      if (val) {
        $(this).val("");
      }
    });

    $(document).on("keyup","#c_mobile",function(){
      let val = isNaN($(this).val());
      if (val) {
        $(this).val("");
      }
    });

  }

  render(){
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">Add Coordinator here by inputting details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/admin/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/admin/coordinator">Coordinator</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Coordinator Registration</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
          <Row>
            <Col md="3">
              <Card className="card-chart">
                <CardBody>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td className="td-noborder">
                          <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                          <a href="#c_coordinator_details" className="anchorItemDetail">Coordinator Details</a>
                          <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card id="c_spil_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                </CardHeader>
                <CardBody>
                 
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="12">
                            <FormGroup>
                              <div className="form-floating">
                                <Input readonly="true" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">ID</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                </CardBody>
              </Card>
              <Card id="c_coordinator_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Coordinator Details</CardTitle>
                </CardHeader>
                <CardBody>
                 
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_company" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Company</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Email</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_telephone" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Telephone</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Mobile</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <Input id="c_language" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Language</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                             
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <FormGroup>
                          <select id="school_id" className="select2-tc form-control" multiple style={{height:"58px",width:"50%","borderRadius":"5px"}}>
                          </select>
                        </FormGroup>
                      </Col>
                    </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md="3">
            </Col>
            <Col md="9">
              <Card id="c_submissions">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                </CardHeader>
                <CardBody>
 
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="2">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Thumbnail</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                          <input style={{marginLeft: "20px",marginTop: "24px"}} accept="image/*" type="file" id="photo"/>
                          <input type="hidden" id="c_photourl"/>
                        </Row>
                      </Col>
                    </Row>
                  
        
                </CardBody>
              </Card>
              <Row>
                <Col sm="12">
                  <div align="center">
                    <button style={{"background":"#416D86","color":"white"}} className="btn-csconfirm">Create Coordinator</button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
  }
}

export default CoordinatorRegistration;
