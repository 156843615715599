import React from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default_profile.png";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import datepickerFactory from 'jquery-datepicker';
import swal from 'sweetalert';
import DateTime from 'react-datetime';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';

const token = localStorage.getItem('token');

function checkEmailExist(elem) {
  console.log(token)
  if (elem.val()) {
    var settings = {
      "url": base_api_url + "/api/v1/authentication/isemailtaken",
      "method": "POST",
      "timeout": 0,
      "headers": {
        "Content-Type": "text/plain",
        "Authorization": `Bearer ${token}`,
      },
      "data": elem.val(),
    };

    $.ajax(settings).done(function (response) {
      if (response.response==true) {
        elem.parent().append("<span class='errorCs'>Email already taken!</span>");
        swal("Failed!", "Email already taken!", "error");
      } else {
        uploadFile();
      }
    });
  }
  return 0;
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#s_photourl").val(response.data[0].path);
    submitData();
  });
}

function sendMail(name, email) {
  let data = JSON.stringify({
    "name": name,
    "email": email
  });

  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: file_api_url + '/api/v1/student/email/registration',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(JSON.stringify(response.data));
  })
  .catch((error) => {
    console.log(error);
  });
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));

  let urlTarget = base_api_url + "/api/v1/student/registerbyagent?agentid=" + user_login.reference_id;
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let arrivaldate;
  let departuredate;

  if ($("#flight_arrivaldate").val()) {

    let arrivaldateraw = new Date($("#flight_arrivaldate").val());
    arrivaldate = arrivaldateraw.getTimezoneOffset() * 60000;
    arrivaldate = (new Date(arrivaldateraw - arrivaldate)).toISOString().slice(0, -1);

    arrivaldate = arrivaldate.split("T")
    arrivaldate = arrivaldate[0] +"T"+ $("#flight_arrivaltime").val();

  }

  if ($("#flight_departuredate").val()) {
    let departuredateraw = new Date($("#flight_departuredate").val())
    departuredate = departuredateraw.getTimezoneOffset() * 60000;
    departuredate = (new Date(departuredateraw - departuredate)).toISOString().slice(0, -1);

    departuredate = departuredate.split("T")
    departuredate = departuredate[0] +"T"+ $("#flight_departuretime").val();
  }

  let dateofbirthraw = new Date($("#student_date_of_birth").val());
  let dateofbirth = dateofbirthraw.getTimezoneOffset() * 60000;
  dateofbirth = (new Date(dateofbirthraw - dateofbirth)).toISOString().slice(0, -1);

  let programmerulesstatus = false;

  if ($("#programme_rule_status").val()=="Approved") {
    programmerulesstatus = true;
  }

  let photosharingagreedandread = Number($("#photo_sharing_agreed_and_read").val());

  let flightadminapproved = "PENDING";

  if ($("#flight_number").val()!=="") {
    flightadminapproved = "APPROVED";
  }

  let requestData = {
    adminapprovalstatus: "PENDING",
    flightadminapproved: flightadminapproved,
    age: $("#student_age").val(),
    allergies: $("#student_allergies").val(),
    arrivaldate: arrivaldate,
    dateofbirth: dateofbirth,
    departuredate: departuredate,
    diatryrequirements: $("#student_diatry_requirements").val(),
    emailaddress: $("#student_email_address").val(),
    familybrotherssisters: $("#student_family_brother_sister").val(),
    firstname: $("#student_first_name").val(),
    fkagentid: user_login.reference_id,
    //fkhostfamilyid: $("#hf_id").val(),
    fkschoolid: $("#school_id").val(),
    flight_type: $("#flight_type").val(),
    flight_airline:$("#flight_airline").val(),
    flight_arrivaldate:arrivaldate,
    flight_destination:$("#flight_destination").val(),
    flight_departuredate:departuredate,
    flight_origin:$("#flight_origin").val(),
    flight_arrivalpickup:$("#flight_arrivalpickup").val(),
    flight_reasonforjourney:$("#flight_reasonforjourney").val(),
    flight_number:$("#flight_number").val(),
    gender: $("#student_gender").val(),
    hobbies: $("#student_hobbies").val(),
    id: $("#id").val(),
    irishmobilenumber: $("#student_irish_mobile").val(),
    isactive: true,
    lastname: $("#student_last_name").val(),
    lengthofstay: $("#student_length_of_stay").val(),
    medicalcondition: $("#student_medical_condition").val(),
    meetings: $("#student_meeting").val(),
    mobilenumber: $("#student_mobile").val(),
    mothersmaidenname: $("#student_mother_maiden_name").val(),
    nationality: $("#student_nationality").val(),
    onanymedication: $("#student_on_any_medication").val(),
    passportnumber: $("#student_passport").val(),
    photosharingagreedandread: photosharingagreedandread,
    photourl: $("#s_photourl").val(),
    programmerulesstatus: programmerulesstatus,
    reference: $("#reference").val(),
    religion: $("#student_religion").val(),
    results: $("#student_results").val(),
    schoolyearstudentwillstudyinireland: $("#student_school_year_student").val(),
    specialrequests: $("#student_special_requests").val(),
    telephonenumer: $("#student_telephone").val(),
    notes:$("#student_notes").val(),
    timestamp: timestamp
  }

  sendMail($("#student_first_name").val() + " " + $("#student_last_name").val(), $("#student_email_address").val());

  return axios.post(urlTarget, requestData).then(res => {
    swal("Success","Successfully create data!","success");
    setTimeout(function(){
      window.location.href = window.location.origin + "/" + user_login.role + "/student";
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function getDataHf() {
  let selEl = "#hf_id";

  return axios.get(base_api_url + '/api/v1/hf').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-familyname="+datas[i].familyname+" data-mothername="+datas[i].mothername+" data-datevisited="+datas[i].datevisited+" data-hostfamilychild="+datas[i].nohostfamilychild+" data-fathername="+datas[i].fathername+" data-address="+datas[i].address+" value="+datas[i].id+">"+datas[i].familyname+"</option>");
    }

  })

}

function getDataAgent() {

  let selEl = "#agent_id";

  return axios.get(base_api_url + '/api/v1/agent').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    for (var i=0;i<datas.length;i++) {
      $(selEl).append(`<option data-company=${datas[i].company} data-name=${datas[i].name} value=${datas[i].id}>${datas[i].name}</option>`);
    }

    let user_login = JSON.parse(localStorage.getItem('user_login'));

    $("#agent_id").val(user_login.reference_id).trigger("change");
    $("#agent_id").prop("disabled",true);
  })
}

function getDataSchool() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

class StudentRegistration extends React.Component {

  componentDidMount(){

    datepickerFactory($);
    flatpickr("#flight_arrivaldate",{dateFormat: 'F d, Y'});
    flatpickr("#flight_arrivaldate2",{dateFormat: 'F d, Y'});
    flatpickr("#flight_departuredate",{dateFormat: 'F d, Y'});
   // $("#flight_pickuplocation").datepicker({dateFormat: "MM dd, yyyy"});
    flatpickr('#hf_date_visited', {dateFormat: 'F d, Y'});
    flatpickr("#flight_date",{dateFormat: 'F d, Y'});
    flatpickr("#student_date_of_birth",{dateFormat: 'F d, Y'});

    $("#flight_time").val("00:00");

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      $(".errorCs").remove();
      
      let notValid = 0;
      let msgval = "";
      let cv;

      if ($("#photo").val()==="") {
        $("#photo").parent().parent().append("<span class='errorCs'>File required!</span>");
        notValid+=1;
      } 

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          cv = $(formField).parent().find("label").text();
          if (cv!=="" && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden"  && !$(formField).attr("readonly")) {
            msgval = cv + " is required! <br/>";
            $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
            notValid += 1;
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        checkEmailExist($("#student_email_address"));
      }
      
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    getDataSchool()
    getDataAgent()
    //getDataHf()    

    $(document).on("change","#agent_id",function(e){
      $("#agent_name").val($(this).find(':selected').data('name'));
      $("#agent_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("change","#school_id",function(e){
      $("#school_name").val($(this).find(':selected').data('name'));
      $("#school_country").val($(this).find(':selected').data('country'));
      $("#school_town").val($(this).find(':selected').data('town'));
      $("#school_type").val($(this).find(':selected').data('type'));
    });

    $(document).on("change","#hf_id",function(e){
      $("#hf_family_name").val($(this).find(':selected').data('familyname'));
      $("#hf_father_name").val($(this).find(':selected').data('fathername'));
      $("#hf_mother_name").val($(this).find(':selected').data('mothername'));
      $("#hf_address").val($(this).find(':selected').data('address'));
      let dvraw = $(this).find(':selected').data('datevisited');
      let dv = new Date(dvraw);
      dv = dv.toString().split(" ");
      dv = dv[1] + " " + dv[2] + ", " + dv[3];
      $("#hf_date_visited").val(dv);
      $("#hf_host_family_child").val($(this).find(':selected').data('hostfamilychild'));
    });

    $(document).on("change","#student_date_of_birth",function(){
      let dob = new Date($(this).val());
      console.log(dob)
      var today = new Date();
      var age = Math.floor((today-dob) / (365.25 * 24 * 60 * 60 * 1000));
      console.log(age)
      $('#student_age').val(age);
    });

    $(document).on("change","#switch_programme_rule_status",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#programme_rule_status").val("Approved").trigger("change");
      } else {
        $("#programme_rule_status").val("Declined").trigger("change");
      }
    });

    $(document).on("change","#switch_photo_sharing_agreed_and_read",function(){
      let val = String($(this).is(':checked'));

      if ($(this).is(':checked')==true) {
        $("#photo_sharing_agreed_and_read").val("Approved").trigger("change");
      } else {
        $("#photo_sharing_agreed_and_read").val("Declined").trigger("change");
      }
    });

  }

  render(){
    return (
      <>
        <PanelHeader size="sm"
          content={
            <div className="content">
              <div className="header text-left">
                <span className="panelHeaderDesc">View and manage the Standard Reports.</span>
                <nav aria-label="breadcrumb" role="navigation">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/agent/overview">Dashboard</a></li>
                    <li className="breadcrumb-item"><a href="/agent/student">Students</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Student Registration</li>
                  </ol>
                </nav>
              </div>
            </div>
          }
        />
        <br/>
        <div className="content">
          <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
            <Row>
              <Col md="3">
                <Card className="card-chart">
                  <CardBody>
                    <Table responsive>
                      <tbody>
                        <tr>
                          <td className="td-noborder">
                            <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                            <a href="#c_student_details" className="anchorItemDetail">Student Details</a>
                            <a href="#c_trip_information" className="anchorItemDetail">Trip Information</a>
                            <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
              <Col md="9">
                <Card id="c_spil_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="id" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">ID</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="reference" className="form-control" style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                                <label htmlFor="pwd">Reference</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <div className="form-floating">
                                <select disabled placeholder="s" id="school_id" style={{borderRadius:"5px"}} className="form-control">
                                  <option value="">Select</option>
                                </select>
                                <label htmlFor="pwd">School</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="school_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="school_country" className="form-control" style={{"borderRadius": "5px"}} placeholder="Country" type="text" />
                                <label htmlFor="pwd">Country</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="school_town" className="form-control" style={{"borderRadius": "5px"}} placeholder="Town" type="text" />
                                <label htmlFor="pwd">Town</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="school_type" className="form-control" style={{"borderRadius": "5px"}} placeholder="Type" type="text" />
                                <label htmlFor="pwd">Type</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
      
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Agent</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md={6}>
                            <FormGroup>
                              <div className="form-floating">
                                <select placeholder="s" id="agent_id" style={{borderRadius:"5px"}} className="form-control">
                                  <option value="">Select Agent</option>
                                </select>
                                <label htmlFor="pwd">Agents</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="agent_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="agent_company" className="form-control" style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                                <label htmlFor="pwd">Company</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_student_details">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Student Details</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_first_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">First Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_last_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Last Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_nationality" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Nationality</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_email_address" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Email Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="input-group mb-3">
                                <div className="form-floating form-floating-group flex-grow-1">
                                    <input id="student_date_of_birth" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                                    <label>Date of Birth</label>
                                </div>
                                <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="student_age" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">Age</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_telephone" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Telephone Number</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_mobile" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Mobile Number</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_irish_mobile" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Irish Mobile Number</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_religion" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Religion</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <select id="student_gender" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID">
                                  <option>Male</option>
                                  <option>Female</option>
                                </select>
                                <label htmlFor="pwd">Gender</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_passport" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Passport Number</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_length_of_stay" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">Length of Stay</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_medical_condition" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Medical Condition</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_on_any_medication" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">On any medication</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_diatry_requirements" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Diatry Requirements</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_allergies" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Allergies</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_mother_maiden_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Mother Maiden Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_family_brother_sister" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Family Brother/Sister</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_school_year_student" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="number" />
                                <label htmlFor="pwd">School year student will study in ireland</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_hobbies" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Hobbies</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input id="student_results" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Results</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <input id="student_special_requests" className="form-control" style={{"borderRadius": "5px"}} placeholder="Special Requests" type="text" />
                            <label htmlFor="pwd">Special Requests</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <div className="form-floating">
                            <input id="student_notes" className="form-control" style={{"borderRadius": "5px"}} placeholder="Notes" type="text" />
                            <label htmlFor="pwd">Notes</label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card id="c_trip_information">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Trip Information</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="input-group mb-3">
                                <div className="form-floating form-floating-group flex-grow-1">
                                    <input id="flight_arrivaldate" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                                    <label>Arrival Date</label>
                                </div>
                                <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="input-group mb-3">
                                <div className="form-floating form-floating-group flex-grow-1">
                                    <input id="flight_departuredate" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                                    <label>Departure Date</label>
                                </div>
                                <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="hf_mother_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Mother Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="hf_father_name" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Father Name</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row>
                          <Col md="6">
                            <FormGroup>
                              <div className="input-group mb-3">
                                <div className="form-floating form-floating-group flex-grow-1">
                                    <input readonly="true" id="hf_date_visited" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                                    <label>Date Visited</label>
                                </div>
                                <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <div className="form-floating">
                                <input readonly="true" id="hf_address" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                                <label htmlFor="pwd">Address</label>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Flight</h4>
                      </FormGroup>
                    </Col>
                  </Row>
   
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_type" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Type</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="input-group mb-3">
                          <div className="form-floating form-floating-group flex-grow-1">
                            <input readonly="true" id="flight_arrivaldate2" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                            <label>Date</label>
                          </div>
                          <span className="input-group-text input-group-text-custom"><i className="now-ui-icons cs-icon-calendar"></i></span>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div>
                          <label style={{position: "relative",top: "28px",left: "14px",fontSize: "10px!important"}}>Arrival Time</label>
                          <DateTime timeFormat="HH:mm"
                            dateFormat={false}
                            inputProps={{id: "flight_arrivaltime" }}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div>
                          <label style={{position: "relative",top: "28px",left: "14px",fontSize: "10px!important"}}>Departure Time</label>
                        <DateTime timeFormat="HH:mm"
                          dateFormat={false}
                          inputProps={{id: "flight_departuretime" }}
                        />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                   <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_airline" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Airline Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating form-floating-group flex-grow-1">
                          <input id="flight_destination" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                          <label>Arrival Airport</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                   <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_origin" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Origin Airport</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                          <div className="form-floating form-floating-group flex-grow-1">
                            <input id="flight_arrivalpickup" className="form-control" style={{"borderRadius": "5px"}} name="code1" placeholder="Code 1"/>
                            <label>Pickup Location</label>
                          </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_number" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Flight Number</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <div className="form-floating">
                          <input id="flight_reasonforjourney" className="form-control" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                          <label htmlFor="pwd">Reason For Journey</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>

                  </CardBody>
                </Card>
                <Card id="c_submissions">
                  <CardHeader>
                    <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                  </CardHeader>
                  <CardBody> 
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Student</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="2">
                            <FormGroup>
                              <div className="form-floating">             
                                <label htmlFor="pwd">Photos</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <img alt="loading" id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}/>
                          <input style={{marginTop: "23px",marginLeft: "10px"}} type="file" id="photo"/>
                          <input value={{profileImg}} type="hidden" id="s_photourl"/>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Programme Rule</h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">     
                                <input value="Declined" id="programme_rule_status" style={{border:"0px"}} type="text" className="form-control" placeholder="ID"/>                     
                                <label htmlFor="pwd">Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div class="material-switch">
                                  <input id="switch_programme_rule_status" type="checkbox"/>
                                  <label for="switch_programme_rule_status" class="label-primary"></label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
        
                    <Row>
                      <Col sm="12">
                        <FormGroup>
                          <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Photographic + Media Conscent </h4>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Row className="bundleBorder">
                          <Col md="11">
                            <FormGroup>
                              <div className="form-floating">
                                <input value="Declined" id="photo_sharing_agreed_and_read" style={{border:"0px"}} type="text" className="form-control" placeholder="ID"/>             
                                <label htmlFor="pwd">Status</label>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md="1">
                            <div className="form-floating">    
                              <div class="material-switch">
                                  <input id="switch_photo_sharing_agreed_and_read" type="checkbox"/>
                                  <label for="switch_photo_sharing_agreed_and_read" class="label-primary"></label>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <div align="center">
                  <button style={{"background":"#416D86","color":"white"}} className="btn-csconfirm">Create Student</button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}

export default StudentRegistration;