import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  Row,
  Col,
  Table
} from "reactstrap";

// core components
import PanelHeader from "components/PanelHeader/PanelHeader.js";
import Switch from "react-bootstrap-switch";
import profileImg from "assets/upload/default.png";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import axios from "axios";
import {base_api_url, file_api_url} from "variables/general.js";
import $ from "jquery";
import swal from 'sweetalert';
import datepickerFactory from 'jquery-datepicker';
import CalculateAge from "components/CustomDateTime/CalculateAge";
import { jsPDF } from "jspdf";

async function getData() {

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  getDocumentsByUserIdAndRole(params.id, "hostfamily");

  let urlTarget = base_api_url + "/api/v1/hf/webgethostfamilybyid?hostFamilyId=" + params.id; 
  let datas;
  const response = await axios.get(urlTarget);
  datas = response.data.response;
  showData(datas);
  // axios.get(urlTarget).then(res => {
  //   datas = res.data.response;
  //   showData(datas);
  // })
  return datas;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function showData(data) {
  localStorage.setItem("detailsData",JSON.stringify(data));
  let school = data.school;
  let coordinator = data.coodinatorDetails;
  let submissions = data.photosSubmitted;
  let gvlist = data.gvlist;
  data = data.hfDetails;
  localStorage.setItem("detailHf" + data.id, JSON.stringify(data));
  $("#attachedStudent").attr("href","/coordinator/host-family/attached/student?id="+data.id)
  $("#id").val(data.id);
  getMusicList(data.id);
  getPetList(data.id);
  $("#t_id").html(data.id);
  $("#t_name").html(data.familyname);
  $("#br_name").html(data.familyname);
  $("#family_name").val(data.familyname);
  $("#mother_name").val(data.mothername);
  $("#mother_occupation").val(data.mothersoccupation);
  $("#mother_smoker").val(String(data.mothersmoker));
  $("#mother_nationality").val(data.mothernationality);
  $("#father_name").val(data.fathername);
  $("#father_occupation").val(data.fatheroccupation);
  $("#father_smoker").val(String(data.fathersmoker));
  $("#father_nationality").val(data.fathernationality);
  $("#telephone_home").val(data.telephonehome);
  $("#mobile").val(data.mobile);
  $("#whatsapp").val(data.whatsapp);
  $("#email").val(data.emailaddress);
  $("#host_family_child").val(data.nohostfamilychild);
  $("#notes").val(data.notes);
  $("#address").val(data.address);
  $("#eircode").val(data.eircode);
  $("#description_of_the_area").val(data.descriptionofthearea);
  $("#distance_to_school").val(data.distancetoschool);
  $("#travel_arrangement").val(data.travelarangements);
  $("#diatry_requirement").val(data.studentwithdietryrequirements);
  $("#available_single_room").val(data.numberofavailablesinglerooms);
  $("#music_instrument").val(String(data.musicalinstruments));
  $("#music_instrument_list").val(data.musicalinstrumentlist);
  $("#pet_list").val(data.petlist);
  $("#pets").val(String(data.pets));
  $("#program_suitable_for").val(data.programmesuitablefor);
  $("#school_children_attend").val(data.schoolschildrenattend);
  $("#programme_rule_status").val(String(data.programmerulesstatus));
  $("#computer_access").val(String(data.accesstocomputer));
  $("#date_visited").val(data.datevisited);
  if (data.photourl) {
    $("#profile").attr("src",data.photourl);
    $("#imgPreview").attr("src",data.photourl);
    $("#f_photourl").val(data.photourl);
  }
  $("#fimgPreview").attr("src",data.familyphotourl);
  $("#f_familyphotourl").val(data.familyphotourl);

  if (data.fkschoolid) {
    getDataSchool(data.fkschoolid);
  } else {
    getDataSchoolOnly();
  }

  if (coordinator) {
    getDataCoordinator(coordinator.id);
  } else {
    getDataCoordinatorOnly();
  }

  if (submissions) {
    for (var is=0;is<submissions.length;is++) {
      let dateS;
      if (submissions[is].timestamp) {
        dateS = new Date(submissions[is].timestamp).toLocaleString();
      }
      let href = submissions[is].imagelocation;
      $("#submissions_list").append(`
        <div class="bundleBorder hostfamily-submissions-list">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${capitalizeFirstLetter(submissions[is].submodule)}</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${dateS}</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-floating">
              <label>${(submissions[is].adminapproved)}</label>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <a target="_blank" href="${href}" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-ov-edit-pen-dark" style="position: relative; top: 8px;"></i>Views Detail</a>
              </div>
            </div>
          </div>
      `);
    }
  }
  
  function calculateAge(dob) {
    const birthday = new Date(dob);
    const ageDiffMs = Date.now() - birthday.getTime();
    const ageDate = new Date(ageDiffMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  if (gvlist) {
    gvlist.filter(gv => {
      const age = calculateAge(gv.dob);
      return age >= 18;
    }).map(gv =>{
      let fileloc = gv.gardavettingfilelocation;

      if (fileloc) {
          $("#gv_list").append(`
            <div class="row">
              <div class="col-md-10">
                <div class="form-group">
                  <div class="form-floating">
                    <input style="border-radius:5px;" class="form-control" value="${gv.name}" type="text" placeholder="ID"/> 
                    <label for="pwd">Person</label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <a target="_blank" href="${fileloc}" style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;"><i class="now-ui-icons cs-download" style="position: relative; top: 8px;"></i></a>
                </div>
              </div>
            </div>
        `);
      } else {
        $("#gv_list").append(`
            <div class="row">
              <div class="col-md-10">
                <div class="form-group">
                  <div class="form-floating">
                    <input readonly style="border-radius:5px;" class="form-control" value="${gv.name}" type="text" placeholder="ID"/> 
                    <label for="pwd">Person</label>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
              </div>
            </div>
        `);
      }
    });
  }
}

function getDataCoordinator(id) {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    axios.get(base_api_url + '/api/v1/coord/getcoordbyid?coordId=' + id).then(res => {
      $(selEl).val(res.data.response.id).trigger("change");
    })

  })
}

function getDataSchool(id) {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

    if (id>0) {
      axios.get(base_api_url + '/api/v1/school/getschoolbyid?schoolId=' + id).then(res => {
        $(selEl).val(res.data.response.SchoolEntity.id).trigger("change");
      })
    }

  })
}

function getDataSchoolOnly() {
  let selEl = "#school_id"; 
  return axios.get(base_api_url + '/api/v1/school').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");

    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-type="+datas[i].type+" data-town="+datas[i].town+" data-country="+datas[i].country+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function getDataCoordinatorOnly() {
  let selEl = "#coordinator_id";

  return axios.get(base_api_url + '/api/v1/coord').then(res => {
    let datas = res.data.response;

    $(selEl).empty();
    $(selEl).append("<option value=''>Select</option>");
    
    for (var i=0;i<datas.length;i++) {
      $(selEl).append("<option data-company="+datas[i].company+" data-name="+datas[i].name+" value="+datas[i].id+">"+datas[i].name+"</option>");
    }

  })
}

function getMusicList(id){
  let selEl = "#music_instrument_list";

  return axios.get(base_api_url + '/api/v1/mi/getMusicalInstrumentsByHostFamily?familyId='+id+'&isActive=true').then(res => {
    if (res.data.response){
      if (res.data.response.length>0) {
        $(selEl).val(res.data.response[0].instrument);
      }
    }
  })
}

function getPetList(id){
  let selEl = "#pet_list";

  return axios.get(base_api_url + '/api/v1/pl/getPetsByHostFamily?familyId='+id+'&isActive=true').then(res => {
    if (res.data.response){
      if (res.data.response.length>0) {
        $(selEl).val(res.data.response[0].pettype);
      }
    }
  })
}

function uploadFile() {
  var bodyFormData = new FormData();
  let imageFile = $("#photo")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#f_photourl").val(response.data[0].path);
    submitData();
  });
}

function uploadDocuments() {
  var bodyFormData = new FormData();
  let imageFile = $("#document_upload")[0].files[0];
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', "hostfamily_id_"+params.id);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    let pdfPath = response.data[0].path;

    if (pdfPath) {

      const parts = pdfPath.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;
      let user_login = JSON.parse(localStorage.getItem('user_login'));

      axios({
        method: "post",
        url: base_api_url + "/api/v1/file",
        data: {
          doctype: extension,
          filelocation: pdfPath,
          filename: fileName,
          fkassociation: 0,
          fkformtype: 0, 
          fkuserid: params.id, 
          fkuserrole: "hostfamily",
          id: null,
          isactive: true,
          notes: "File Upload",
          uploadbyrole:"admin",
          uploadbyid:user_login.reference_id
        }
      }).then(function (response) {

        let fileId = response.data.response.id;

          $("#documents_lists").append(`<div class="bundleBorder row">
            <div class="col-md-3">
              <div class="form-group">
                <div class="form-floating">
                  <label for="pwd">${fileName}</label>
                </div>
              </div>
            </div>
            <div class="col-md-9" align="right">
              <div class="form-group">
                <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${pdfPath}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
                
                </div>
              </div>
            </div>`);

      });

    }
    
  });
}

function getDocumentsByUserIdAndRole(userid, role) {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  let uploadById = user_login.reference_id;
  let uploadByRole = "admin";

  axios({
    method: "GET",
    url: base_api_url + `/api/v1/file/getfilelist?userId=${userid}&userRole=${role}&uploadById=${uploadById}&uploadByRole=${uploadByRole}`
  }).then(function (response) {
    let datas = response.data.response;
    for (var i=0;i<datas.length;i++) {

      const parts = datas[i].filelocation.split("/");

      // Get the last part which contains the file name
      const fileNameWithExtension = parts[parts.length - 1];

      // Split the file name by "." to separate the name and extension
      const fileNameParts = fileNameWithExtension.split(".");
      let fileName = fileNameParts[0];
      const extension = fileNameParts[1];
      fileName = fileName + "." + extension;

      $("#documents_lists").append(`
        <div class="bundleBorder row">
          <div class="col-md-3">
            <div class="form-group">
              <div class="form-floating">
                <label for="pwd">${datas[i].filename}</label>
              </div>
            </div>
          </div>
          <div class="col-md-9" align="right">
            <div class="form-group">
              <a style="color: rgb(113, 113, 113); font-style: normal; font-weight: 400; font-size: 12px; line-height: 16px;" target="_blank" href="${datas[i].filelocation}"><i style="position: relative; top: 8px;" class="now-ui-icons cs-ov-edit-pen-dark"></i> Show File</a>
              
              </div>
            </div>
          </div>
      `);

    }
  });
}

function submitPet(familyName, familyId) {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let requestData = {
    fkfamilyid: familyId,
    fkfamilyname: familyName,
    insideoroutside: "string",
    isactive: true,
    notes: "Pet list",
    pettype: $("#pet_list").val(),
    timestamp: timestamp
  }

  return axios.post(base_api_url + "/api/v1/pl", requestData).then(res => {
    //
  });
}

function submitMusic(familyName, familyId) {
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let requestData = {
    fkfamilyid: familyId,
    fkfamilyname: familyName,
    isactive: true,
    notes: "Pet list",
    instrument: $("#music_instrument_list").val(),
    timestamp: timestamp
  }

  return axios.post(base_api_url + "/api/v1/mi", requestData).then(res => {
    //
  });
}

function uploadFileFamily() {
  var bodyFormData = new FormData();
  let imageFile = $("#fphoto")[0].files[0];
  let user_login = JSON.parse(localStorage.getItem('user_login'));

  bodyFormData.append('file', imageFile);
  bodyFormData.append('username', user_login.email);

  axios({
    method: "post",
    url: file_api_url + "/api/v1/user/upload/file",
    data: bodyFormData,
    headers: { "Content-Type": "multipart/form-data" },
  }).then(function (response) {
    $("#f_familyphotourl").val(response.data[0].path);
    //
  });
}

function submitData() {

  let user_login = JSON.parse(localStorage.getItem('user_login'));
  var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  var timestamp = localISOTime;
  let requestData = JSON.parse(localStorage.getItem("detailHf" + $("#id").val()));

  submitPet($("#family_name").val(), $("#id").val());
  submitMusic($("#family_name").val(), $("#id").val());

  let mothersmoker = false;
  let fathersmoker = false;
  let wifi = false;
  let pets = false;
  let music = false;
  let programmerulesstatus = false;

  if ($("#mother_smoker").val()=="true") {
    mothersmoker = true;
  }

  if ($("#father_smoker").val()=="true") {
    fathersmoker = true;
  }

  if ($("#computer_access").val()=="true") {
    wifi = true;
  }

  if ($("#pets").val()=="true") {
    pets = true;
  }

  if ($("#music_instrument").val()=="true") {
    music = true;
  }

  if ($("#programme_rule_status").val()=="true") {
    programmerulesstatus = true;
  }

  requestData["accesstocomputer"] = wifi;
  requestData["address"] = $("#address").val();
  requestData["datevisited"] = $("#date_visited").val();
  requestData["descriptionofthearea"] = $("#description_of_the_area").val();
  requestData["distancetoschool"] = $("#distance_to_school").val();
  requestData["eircode"] = $("#eircode").val();
  requestData["emailaddress"] = $("#email").val();
  requestData["familyname"] = $("#family_name").val();
  requestData["fathername"] = $("#father_name").val();
  requestData["fathernationality"] = $("#father_nationality").val();
  requestData["fatheroccupation"]= $("#father_occupation").val();
  requestData["fathersmoker"] = fathersmoker;
  requestData["mothername"] = $("#mother_name").val();
  requestData["mothernationality"] = $("#mother_nationality").val();
  requestData["mothersoccupation"] = $("#mother_occupation").val();
  requestData["mothersmoker"] = mothersmoker;
  requestData["numberofavailablesinglerooms"] = $("#available_single_room").val();
  requestData["fkcoordinator"] = $("#coordinator_id").val();
  requestData["fkschoolid"] = $("#school_id").val();
  requestData["photourl"] = $("#f_photourl").val();
  requestData["familyphotourl"] = $("#f_familyphotourl").val();
  requestData["pets"] = pets;
  requestData["musicalinstruments"] = music;
  requestData["programmerulesstatus"] = programmerulesstatus;
  requestData["programmesuitablefor"] = $("#program_suitable_for").val();
  requestData["schoolschildrenattend"] = $("#school_children_attend").val();
  requestData["studentwithdietryrequirements"] = $("#diatry_requirement").val();
  requestData["telephonehome"] = $("#telephone_home").val();
  requestData["wifiavailable"] = wifi;

  return axios.put(base_api_url + "/api/v1/hf/updatebyhostFamilyid?role="+user_login.role, requestData).then(res => {
    swal("Success","Successfully update data!","success");
    setTimeout(function(){
      window.location.reload();
    },500);
  }).catch(function (error) {
    swal("Failed!", "Something went wrong!", "error");
  });
}

function ListChild ({data}){

  for (var i=0;i<data.length;i++){
    if (data[i].age>=18) {
      data[i].url = "/coordinator/report/standard-report/garda-vetting/"+data[i].id+"?id="+ data[i].id;
    } else {
      data[i].url = "/coordinator/report/standard-report/child/"+data[i].id+"?id=" + data[i].id;
    }
  }

  // console.log(data)

  return (
    <>
      {data.map(item => {
        return (
          <Row>
            <div class="bundleBorder hostfamily-child-list" >
              <Col md="2" className="hostfamily-child-list-fullwidth-mobile">
                <FormGroup>
                  <div className="form-floating">
                    <Input value={item.name} style={{"border": "none"}} placeholder="ID" type="text" />
                    <label htmlFor="pwd">Name</label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="2" className="hostfamily-child-list-fullwidth-mobile">
                <FormGroup>
                  <div className="form-floating">
                    <Input value={item.gender} style={{"border": "none"}} placeholder="ID" type="text" />
                    <label htmlFor="pwd">Gender</label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="2" className="hostfamily-child-list-fullwidth-mobile">
                <FormGroup>
                  <div className="form-floating">
                    <Input value={item.dob} style={{"border": "none"}} placeholder="ID" type="text" />
                    <label htmlFor="pwd" style={{"width":"100px"}}>Date of birth</label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="5" className="hostfamily-child-list-fullwidth-mobile">
                <FormGroup>
                  <div className="form-floating">
                    <Input className="hostfamily-child-list-hobbies" value={item.hobbies} placeholder="ID" type="text" />
                    <label htmlFor="pwd">Hobbies</label>
                  </div>
                </FormGroup>
              </Col>
              <Col md="1" className="hostfamily-child-list-fullwidth-mobile">
                <FormGroup>
                  <div className="hostfamily-child-list-view-button">
                    <a href={item.url} target="_blank" >View</a>
                  </div>
                </FormGroup>
              </Col>
            </div>
          </Row>
        )
      })}
    </>
  )
}

class HostFamilyDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      childList: []
    };
  };

  componentDidMount(){
    getData().then(data => {
      this.setState({
        childList: data.gvlist
      })
    });

    $("input").prop("disabled",true);
    $("select").prop("disabled",true);

    $(document).on("click", "#print", function(e) {
    e.preventDefault();
    const doc = new jsPDF();

    let data = JSON.parse(localStorage.getItem('detailsData'));
    let school = data.school;
    let coordinator = data.coodinatorDetails;
    let submissions = data.photosSubmitted;
    let gvlist = data.gvlist;
    data = data.hfDetails;

    // Title and Host Family Details
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text('Host Family Details', 14, 20);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text('-------------------------------------', 14, 25);

    // Host Family Details
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Family Information', 14, 35);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    
    const details = [
        { label: 'Family Name', value: data.familyname },
        { label: 'Mother\'s Name', value: data.mothername },
        { label: 'Mother\'s Occupation', value: data.mothersoccupation },
        { label: 'Mother\'s Smoker', value: String(data.mothersmoker) },
        { label: 'Mother\'s Nationality', value: data.mothernationality },
        { label: 'Father\'s Name', value: data.fathername },
        { label: 'Father\'s Occupation', value: data.fatheroccupation },
        { label: 'Father\'s Smoker', value: String(data.fathersmoker) },
        { label: 'Father\'s Nationality', value: data.fathernationality },
        { label: 'Telephone Home', value: data.telephonehome },
        { label: 'Mobile', value: data.mobile },
        { label: 'WhatsApp', value: data.whatsapp },
        { label: 'Email', value: data.emailaddress },
        { label: 'Number of Host Family Children', value: data.nohostfamilychild },
        { label: 'Notes', value: data.notes },
        { label: 'Address', value: data.address },
        { label: 'Eircode', value: data.eircode },
        { label: 'Description of the Area', value: data.descriptionofthearea },
        { label: 'Distance to School', value: data.distancetoschool },
        { label: 'Travel Arrangement', value: data.travelarangements },
        { label: 'Dietary Requirement', value: data.studentwithdietryrequirements },
        { label: 'Available Single Rooms', value: data.numberofavailablesinglerooms },
        { label: 'Musical Instruments', value: String(data.musicalinstruments) },
        { label: 'Music Instrument List', value: data.musicalinstrumentlist },
        { label: 'Pet List', value: data.petlist },
        { label: 'Pets', value: String(data.pets) },
        { label: 'Program Suitable For', value: data.programmesuitablefor },
        { label: 'School Children Attend', value: data.schoolschildrenattend },
        { label: 'Programme Rule Status', value: String(data.programmerulesstatus) },
        { label: 'Computer Access', value: String(data.accesstocomputer) },
        { label: 'Date Visited', value: data.datevisited }
    ];
    
    let y = 45;
    details.forEach(detail => {
        if (y > 270) { // Add new page if content is too long
            doc.addPage();
            y = 20;
        }
        doc.text(`${detail.label}:`, 14, y);
        doc.text(detail.value || 'N/A', 60, y);
        y += 10;
    });

    if (coordinator) {
        // Coordinator Details
        doc.addPage();
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text('Coordinator Details', 14, 20);
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`Coordinator ID: ${coordinator.id}`, 14, 30);
        doc.text(`Coordinator Name: ${coordinator.name}`, 14, 40);
        doc.text(`Coordinator Email: ${coordinator.email}`, 14, 50);
    }

    if (submissions && submissions.length > 0) {

    // Submissions Details
    doc.addPage();
    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('Submissions', 14, 20);
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
        submissions.forEach((submission, index) => {
            if (y > 270) { // Add new page if content is too long
                doc.addPage();
                y = 20;
            }
            let dateS = submission.timestamp ? new Date(submission.timestamp).toLocaleString() : "N/A";
            let yOffset = 30 + (index * 40);
            doc.text(`Submission ${index + 1}:`, 14, yOffset);
            doc.text(`Module: ${capitalizeFirstLetter(submission.submodule)}`, 14, yOffset + 10);
            doc.text(`Date: ${dateS}`, 14, yOffset + 20);
            doc.text(`Status: ${submission.coordapproved}`, 14, yOffset + 30);
            //doc.text(`Link: ${submission.imagelocation}`, 14, yOffset + 40);
        });
    }

    // Save PDF
    doc.save('host-family-details.pdf');
});


    // getData();
    datepickerFactory($);
    $("#date_visited").datepicker({dateFormat:"dd/mm/yy"});

    let optional = ["available_single_room","music_instrument_list","pet_list","date_visited"]

    $(document).on("click",".btn-csconfirm",function(e){
      e.preventDefault();
      
      let notValid = 0;
      let msgval = "";
      let cv;
      $(".errorCs").remove();

      $.each($('form input'), function(index, formField) {
        if ($(formField).val()==="") {
          if (!optional.includes($(formField).attr("id"))) {
            cv = $(formField).parent().find("label").text();
            if (cv!==""  && $(formField).attr("type")!=="file" && $(formField).attr("type")!=="hidden") {
              msgval = cv + " is required! <br/>";
              $(formField).parent().append("<span class='errorCs'>" + msgval + "</span>");
              notValid += 1;
            }
          }
        }
      });

      if (notValid>0) {
        //$("#msgValidation").show();
      } else {
        if ($("#photo").val()=="") {
          submitData();
        } else {
          uploadFile();
        }
      }
    });

    $(document).on("click",".close",function(){
      $(this).parent().parent().hide();
    });

    $('#photo').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#imgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
        } else {
          $('#photo').val("");
          $("#imgPreview").attr("src","");
          $('#photo').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $('#fphoto').change(function(){
      const file = this.files[0];
      if (file){
        $(".errorCs").remove();
        if (file.type=="image/jpeg" || file.type=="image/png") {
          let reader = new FileReader();
          reader.onload = function(event){
            $('#fimgPreview').attr('src', event.target.result);
          }
          reader.readAsDataURL(file);
          uploadFileFamily();
        } else {
          $('#fphoto').val("");
          $("#fimgPreview").attr("src","");
          $('#fphoto').parent().parent().append("<span class='errorCs'>The image must be a file of type: jpg, jpeg, png.</span>");
        }
      }
    });

    $(document).on("change","#school_id",function(e){
      $("#school_name").val($(this).find(':selected').data('name'));
      $("#school_country").val($(this).find(':selected').data('country'));
      $("#school_town").val($(this).find(':selected').data('town'));
      $("#school_type").val($(this).find(':selected').data('type'));
    });

    $(document).on("change","#coordinator_id",function(e){
      $("#coordinator_name").val($(this).find(':selected').data('name'));
      $("#coordinator_company").val($(this).find(':selected').data('company'));
    });

    $(document).on("click",".uploadDoc",function(e){
      e.preventDefault();
      
      if ($("#document_upload").val()=="") {
        alert("Please upload the PDF/Image file!");
      } else {
        let ftype = $("#document_upload")[0].files[0].type;
        if (ftype!=="application/pdf"&&ftype!=="image/jpeg"&&ftype!=="image/png") {
          alert("Only PDF/Image file allowed!");
        } else {
          uploadDocuments();
        }
      }

    });

    $(document).on("click",".delDoc",function(e){
      e.preventDefault();

      let curPar = $(this).parent().parent().parent();
      let fileId = $(this).data("id");

      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });

      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this file ?",
        icon: "warning",
        buttons: ["Cancel", "Yes, delete it"],
      })
      .then(willDelete => {
        if (willDelete) {
          axios({
            method: "delete",
            url: base_api_url + `/api/v1/file/${fileId}/${params.id}/hostfamily`
          }).then(function (response) {
              $(curPar).remove();
          });
        }
      });

    });

  }

  handleSwitch(elem, state) {
  }

  render(){
    return (
    <>
      <PanelHeader size="sm"
        content={
          <div className="content">
            <div className="header text-left">
              <span className="panelHeaderDesc">View and manage the Host Families Details.</span>
              <nav aria-label="breadcrumb" role="navigation">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/agent/overview">Dashboard</a></li>
                  <li className="breadcrumb-item"><a href="/agent/student">Students</a></li>
                  <li className="breadcrumb-item active" aria-current="page" id="br_name">Host Family Detail</li>
                </ol>
              </nav>
            </div>
          </div>
        }
      />
      <br/>
      <div className="content">
        <Form name="formsubmit" id="formsubmit" action="/" className="form-horizontal" method="post">
          <Row>
            <Col md="3">
              <Card className="card-chart">
                <CardBody>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td className="td-noborder">
                          <a href="#c_spil_details" className="anchorItemDetail">SPIL Details</a>
                          <a href="#c_host_family_details" className="anchorItemDetail">Host Family Details</a>
                          <a href="#c_submissions" className="anchorItemDetail">Submissions</a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            <Col md="9">
              <Card>
                <CardHeader className="card-header-profile">
                  <Row>
                    <Col md={2}>
                      <img id="profile" style={{float: "left",width:"76px",height:"76px",position:"relative",bottom:"18px"}} src={profileImg}></img>
                    </Col>
                    <Col md={8}>
                      <p className="profile-title" id="t_name"></p>
                      <p className="profile-desc" id="t_id"></p>
                    </Col>
                    <Col md={2}>
                      <button className="btn btn-warning" id="print"><i className="fa fa-print"/> Print</button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
              <Card id="c_spil_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">SPIL Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>HostFamily</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="id" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">ID</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>School</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="school_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">School</label>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <div className="form-floating">
                          <Input readonly="true" id="school_name" style={{"borderRadius": "5px"}} placeholder="Name" type="text" />
                          <label htmlFor="pwd">Name</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Coordinator</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormGroup>
                        <div className="form-floating">
                          <select placeholder="s" id="coordinator_id" style={{borderRadius:"5px"}} className="form-control">
                            <option value="">Select</option>
                          </select>
                          <label htmlFor="pwd">Coordinator</label>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md={6}>
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="coordinator_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <div className="form-floating">
                              <Input readonly="true" id="coordinator_company" style={{"borderRadius": "5px"}} placeholder="Reference" type="text" />
                              <label htmlFor="pwd">Company</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="c_host_family_details">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Host Family Details</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Host Families</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="family_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Family Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mother_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mother Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mother_occupation" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mothers Occupation</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="mother_smoker" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false" selected>No</option>
                              </select>
                              <label htmlFor="pwd">Mothers Smoker</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mother_nationality" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Mother Nationality</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="father_name" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Name</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="father_occupation" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Occupation</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="father_smoker" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false" selected>No</option>
                              </select>
                              <label htmlFor="pwd">Father Smoker</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="father_nationality" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Father Nationality</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="telephone_home" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone Home</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="mobile" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Telephone Mobile</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="email" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Email</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="whatsapp" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Whatsapp Y/N</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="date_visited" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Date Visited</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="host_family_child" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Host Family Child</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <ListChild data={this.state.childList}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="notes" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Notes</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="address" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Address</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="eircode" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Eircode</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="description_of_the_area" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Description of the Area</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="distance_to_school" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Distance to School / Travel Arrangements</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        {/* <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="travel_arrangement" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Travel Arrangements</label>
                            </div>
                          </FormGroup>
                        </Col> */}
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="diatry_requirement" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Are you ok of student has specific dietry requirements</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="available_single_room" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Number of Available single rooms</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="computer_access" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label htmlFor="pwd">Have you wifi / computer access</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="music_instrument" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label htmlFor="pwd">Musical Instrument</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="pets" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Yes</option>
                                <option value="false">No</option>
                              </select>
                              <label htmlFor="pwd">Pets</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="music_instrument_list" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Musical Instrument List</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="pet_list" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Pets List</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="program_suitable_for" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">Programme Suitable For</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <div className="form-floating">
                              <Input id="school_children_attend" style={{"borderRadius": "5px"}} placeholder="ID" type="text" />
                              <label htmlFor="pwd">School Children Attend</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <div className="form-floating">
                              <select className="form-control" id="programme_rule_status" style={{"borderRadius": "5px"}} placeholder="ID">
                                <option value="true">Active</option>
                                <option value="false">Inactive</option>
                              </select>
                              <label htmlFor="pwd">Programme Rule Status</label>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card id="c_submissions">
                <CardHeader>
                  <CardTitle tag="h4" className="panelTitle">Submissions</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Photo</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <Row className="bundleBorder">
                        <Col md="2">
                          <FormGroup>
                            <div className="form-floating">             
                              <label htmlFor="pwd">Photos</label>
                            </div>
                          </FormGroup>
                        </Col>
                        <img id="imgPreview" style={{float: "left",width:"76px",height:"76px",position:"relative"}} src={profileImg}></img>
                        <input style={{marginLeft: "20px",marginTop: "24px"}} accept="image/*" type="file" id="photo"/>
                        <input type="hidden" id="f_photourl"/>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Submissions</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" id="submissions_list">
                    </Col>
                  </Row>

                   <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Documents & Images</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12" id="documents_lists">
                    </Col>
                    <Col sm="12">
                      <input style={{marginLeft: "20px",marginTop: "24px"}} type="file" id="document_upload"/> <button className="btn btn-primary uploadDoc">Upload</button>
                    </Col>
                  </Row>
                 
                  <Row>
                    <Col sm="12">
                      <FormGroup>
                        <h4 style={{"fontFamily": "Noto Sans","fontStyle": "normal","fontWeight": 400,"fontSize": "16px","lineHeight": "24px","color": "#70B2D8"}}>Garda Vetting</h4>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} id="gv_list">
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
  }

}

export default HostFamilyDetail;