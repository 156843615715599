import React from "react";

// reactstrap components
import {
  Container,
  Col,
  Row
} from "reactstrap";

import bgImage from "assets/img/spill/bg-login.svg";

function Home() {
  let currentUrl = window.location.origin;

  console.log("Test");

  React.useEffect(() => {
    localStorage.removeItem("user_login");
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);
  return (
    <>
      <div className="content" style={{ backgroundSize: "cover", backgroundImage: "url(" + bgImage + ")" }}>
        <div className="login-page">
          <Container>
            <Row>
              <Col xs={12} md={12} lg={12} className="ml-auto mr-auto">
                <a href={currentUrl + "/auth/login/admin"} className="btn btn-primary"><b>Login as Admin</b></a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12} className="ml-auto mr-auto">
                <a href={currentUrl + "/auth/login/agent"} className="btn btn-primary"><b>Login as Agent</b></a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12} className="ml-auto mr-auto">
                <a href={currentUrl + "/auth/login/coordinator"} className="btn btn-primary"><b>Login as Coordinator</b></a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12} className="ml-auto mr-auto">
                <a href={currentUrl + "/auth/login/hostfamily"} className="btn btn-primary"><b>Login as Hostfamily</b></a>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12} lg={12} className="ml-auto mr-auto">
                <a href={currentUrl + "/auth/login/student"} className="btn btn-primary"><b>Login as Student</b></a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Home;
