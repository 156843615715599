import React from "react";
import axios from "axios";
import $ from "jquery";
import swal from 'sweetalert';

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  Button
} from "reactstrap";
import {base_api_url, spil_url, file_api_url} from "variables/general.js";

// core components
import nowLogo from "assets/img/spill/logo.png";
import bgImage from "assets/img/spill/bg-login.svg";

class ForgotPassword extends React.Component {
  componentDidMount(){

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let person = params.role;

    if (params.message) {
      $("#msgSuccess").hide();
      $("#msgFailed").hide();
      $("#msgValidation").show();
      $("#contentValidation").html("Unauthorized or session expired!");
    }

    $(document).on("click",".submitForgot",function(e){
      e.preventDefault();

      $(".errorCs").remove();
        
        let email = $("#email").val();

        if (!email){
        
          swal("Failed!", "Email required!", "error");
        
        } else {

          let data = JSON.stringify({
            "email": email
          });

          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: file_api_url + '/api/v1/user/request/deletion',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };

          axios.request(config)
          .then((response) => {
            swal("Success","We have received your request for account deletion. Our team will review and validate your request, and we will get back to you as soon as possible.","success");
          
            setTimeout(function(){
              window.location.href = window.location.origin;
            },500);
          })
          .catch((error) => {
            swal("Ops!", "Something wrong!", "error");
          });
        }
    })
    $(document).on("click",".close",function(){
      $(this).parent().parent().fadeOut();
    });
  }
  render(){
  return (
    <>
      <div className="content" style={{ backgroundSize: "cover", backgroundImage: "url(" + bgImage + ")" }}>
        <div className="login-page">
          <Container>
            <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
              <Form>
                <Card className="card-login card-plain" style={{height:"520px"}}>
                  <CardHeader>
                    <div className="logo-container" style={{margin:0}}>
                      <img src={nowLogo} alt="now-logo" />
                    </div>
                  </CardHeader>
                  <CardBody className="cs-card-body">
                    <div align="center">
                      <h3 style={{fontFamily: 'Noto Sans',fontStyle: "normal",fontWeight: 600,fontSize: "28px",color: "#416D86"}}>Request for Account Deletion</h3>
                      <p>Send a request to delete your account and personally identifiable informatiion that is stored on our system. You will receive and email to verify your request. Once the request is verified we will take care of deleting your account.</p>
                    </div>
                    <InputGroup
                      className={"no-border form-control-lg"}
                    >
                      <Input id="email"
                        type="text" className='email input-txt'
                        placeholder="Email" required
                      />
                    </InputGroup>
                    <InputGroup align="center">
                    <Button
                      block
                      color="primary"
                      size="lg"
                      className="mb-3 btn-round submitForgot"
                    >
                      Send
                    </Button>
                    </InputGroup>
                  </CardBody>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
      </div>
    </>
  );

  }
}

export default ForgotPassword;